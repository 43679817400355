import { ApiGatewayPathBuilder, CloudRunPathBuilder, Env, IEmployerEnvironment } from 'shared-lib'

const env: Env = 'production'
const projectNumber = '918489190359'
const projectHash = 'w433q2d5qq'
const pathBuilder = new ApiGatewayPathBuilder(env)
const cloudRunPathBuilder = new CloudRunPathBuilder(projectHash)

export const environment: IEmployerEnvironment = {
  production: true,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyDCjJ68iZtelmCX9eh6FVyboIQ4O8xwEK8',
    authDomain: 'cnect-prod-100.firebaseapp.com',
    projectId: 'cnect-prod-100',
    storageBucket: 'cnect-prod-100.appspot.com',
    messagingSenderId: projectNumber,
    appId: '1:918489190359:web:324c7c53ff208b677a8592',
    databaseURL: 'https://cnect-prod-100.firebaseio.com',
    measurementId: 'G-8GSZB39LCK',
  },

  services: {
    cloudFunctions: 'https://us-central1-cnect-prod-100.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    events: pathBuilder.build('events'),
    applicationHistory: pathBuilder.build('applicationHistory'),
    user: pathBuilder.build('user'),
    content: pathBuilder.build('content'),
    employer: pathBuilder.build('employer'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    jobsIntegration: pathBuilder.build('jobsIntegration'),
    notifications: pathBuilder.build('notifications'),
    customer: pathBuilder.build('customer'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    candidatePool: pathBuilder.build('candidatePool'),
    sse: cloudRunPathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },

  // We can turn authentication off in case we are using this application inside an SDK and do not want to have any authentication
  authentication: false,
  themesEnabled: true,
  v2ReCaptcha: '6LeaW0ocAAAAAAElSsVOmLLXYtSe6qlC8N8ZuXkt',
  tenantId: 'B2B-USER-eomrj',
  algoliaAppId: '8KSBN2S7IA',
  cdnCname: 'content.cnected.com',
  basePlatformHost: 'employer.cnected.com',
  candidateExternalJobUrl: 'https://network.cnected.com/#/landing/job/',
  candidateEmailActionRoute: 'https://network.cnected.com/#/auth/actions',
  careersPageBaseLink: 'https://careers.cnected.com/',
  employerBaseUrl: 'https://employer.cnected.com/',
  employerEmailSuccessRoute: 'https://employer.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: '32bb27d',

  tenant: {
    appConfigBaseURL: 'https://content.cnected.com/assets/bootstrap',
    defaultTenant: 'employer.cnected.com',
  },
}
