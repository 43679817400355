import { DatePipe, NgClass, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { CandidateRatingMiniComponent } from '@employer/app/modules/jobs/components/candidate-rating-mini/candidate-rating-mini.component'
import { IMaskedJobPostApplication } from '@employer/app/modules/jobs/components/candidate-share-profile/job-post-application-share.model'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11ExternalLinkModule } from '@engineering11/ui-lib/e11-external-link'
import { TranslateModule } from '@ngx-translate/core'
import { SharedLibModule } from 'shared-lib'

@Component({
  selector: 'shared-application-detail-header',
  standalone: true,
  imports: [NgClass, NgIf, SharedLibModule, E11ChartPercentageModule, TranslateModule, DatePipe, CandidateRatingMiniComponent, E11ExternalLinkModule],
  template: `
    <div class="e11-flex e11-w-full e11-mb-8">
      <div class="e11-grow">
        <div class="e11-flex e11-justify-start e11-items-start e11-mb-2">
          <h3 [ngClass]="{ 'e11-h3-skeleton': !candidateApplication }" class="e11-mr-2">
            {{ maskDetails ? 'Name protected' : candidateApplication?.firstName + ' ' + candidateApplication?.lastName }}
          </h3>

          <candidate-rating-mini *ngIf="candidateApplication"></candidate-rating-mini>
        </div>
        <p class="e11-text-skin-grey e11-text-sm e11-mb-2" *ngIf="candidateApplication?.currentJobTitle">
          {{ candidateApplication?.currentJobTitle }}
        </p>

        <div class="e11-flex e11-items-center e11-justify-start e11-mb-2">
          <p *ngIf="!candidateApplication" class="e11-mb-0 e11-p-skeleton"></p>
          <p *ngIf="candidateApplication" class="e11-mb-1">
            <span class="e11-font-primary-demibold e11-mr-1">{{ 'Job Match:' | translate }}</span>
            <span class="e11-text-sm e11-mr-2">{{ 'Score: ' }}{{ candidateApplication?.jobMatchScore || 0 }}%</span>
          </p>
          <job-application-info-icons *ngIf="candidateApplication?.candidateResponses" [responses]="candidateApplication?.candidateResponses" />

          <div class="e11-mt-1">
            <p *ngIf="!candidateApplication" class="e11-mb-0 e11-p-skeleton"></p>
          </div>
        </div>
        <div class="e11-flex e11-gap-6">
          <div *ngIf="!candidateApplication" class="e11-chart-percentage-skeleton"></div>
          <e11-chart-percentage
            *ngIf="candidateApplication"
            [data]="candidateApplication.jobMatchScore || 0"
            [imageUrl]="candidateApplication.photoURL"
            [size]="'110px'"
          ></e11-chart-percentage>

          <div class="e11-flex e11-flex-col">
            <!-- Skeletons - Separated from data because it is conditional -->
            <p *ngIf="!candidateApplication" class="e11-mb-1 e11-p-skeleton"></p>
            <p *ngIf="!candidateApplication" class="e11-mb-1 e11-p-skeleton"></p>
            <p *ngIf="!candidateApplication" class="e11-mb-1 e11-p-skeleton"></p>
            <!-- Date -->
            <p *ngIf="candidateApplication?.appliedDate" class="e11-flex e11-items-center e11-text-sm e11-mb-0">
              <span class="material-icons-outlined e11-mr-2 md-18">today</span>
              <span class="e11-truncate"> {{ 'Applied on' | translate }} : {{ candidateApplication?.appliedDate | date : 'shortDate' }} </span>
            </p>
            <p *ngIf="maskDetails || candidateApplication?.email" class="e11-flex e11-items-center e11-mb-0">
              <span class="material-icons-outlined e11-mr-2 md-18"> email </span>
              <span class="e11-text-sm e11-truncate ">
                {{ maskDetails ? ('Email protected' | translate) : candidateApplication?.email }}
              </span>
            </p>
            <!-- Phone -->
            <p *ngIf="maskDetails || candidateApplication?.phoneNumber" class="e11-flex e11-items-center e11-mb-0">
              <span class="material-icons-outlined e11-mr-2 md-18"> smartphone </span>
              <span class="e11-text-sm e11-truncate ">
                {{ maskDetails ? ('Phone number protected' | translate) : candidateApplication?.phoneNumber }}
              </span>
            </p>

            <!-- Address -->
            <div *ngIf="maskDetails || candidateApplication?.address" class="e11-flex e11-mb-0 e11-items-center e11-truncate e11-text-sm">
              <span class="material-icons-outlined e11-mr-2 md-18"> cottage </span>
              <span class="e11-truncate e11-text-sm" *ngIf="maskDetails">Address protected</span>
              <span
                *ngIf="candidateApplication && !maskDetails"
                [ngClass]="{
                  'e11-text-skin-light e11-italic e11-relative e11-truncate e11-text-sm':
                    !candidateApplication.address || (!candidateApplication.address?.city && !candidateApplication.address?.state)
                }"
              >
                <span *ngIf="candidateApplication.address?.city">{{ candidateApplication.address?.city }}</span>
                <span *ngIf="candidateApplication.address?.state"> , {{ candidateApplication.address?.state }} </span>
                <span *ngIf="!candidateApplication.address || (!candidateApplication.address?.state && !candidateApplication.address?.city)">
                  {{ 'Address not added' | translate }}
                </span>
              </span>
              <span class="e11-skeleton-empty" *ngIf="!candidateApplication"></span>
            </div>

            <!-- Website -->
            <p *ngIf="maskDetails || candidateApplication?.website" class="e11-flex e11-items-center e11-mb-0">
              <span class="material-icons-outlined e11-mr-2 md-18"> launch </span>
              <span class="e11-text-sm e11-truncate  ">
                @if (maskDetails) {
                {{ 'Website protected' | translate }}
                } @else {
                <a
                  e11ExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  [href]="candidateApplication?.website"
                  [title]="'Click to view in a new browser tab' | translate"
                  class="hover:e11-text-skin-primary-accent"
                >
                  {{ candidateApplication?.website }}
                </a>
                }
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SharedApplicationDetailHeaderComponent {
  @Input() maskDetails: boolean = false
  @Input() candidateApplication: IMaskedJobPostApplication | undefined

  constructor() {}
}
