import { selectors } from '@admin/app/store/selectors'
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { IEmployerUser } from '@cnect/user-shared'
import { Permission } from '@employer/app/config/permission.config'
import { ROUTES } from '@employer/app/services/navigation.service'
import { PermissionStore } from '@engineering11/access-web'
import { CustomerPermissions, CustomerStore } from '@engineering11/customer-web'
import { Store } from '@ngrx/store'
import { map, Observable } from 'rxjs'
import { OnLogOut } from 'shared-lib'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { environment } from '@employer/environments/environment'
import { AuthStore } from '@employer/app/store/auth.store'

@Component({
  selector: 'profile-dropdown',
  templateUrl: 'profile-dropdown.component.html',
})
export class ProfileDropdownComponent implements OnInit {
  @ViewChild('qrCodeModalContent') qrCodeModalContent!: TemplateRef<any>
  routes = ROUTES
  dropDownOpen = false
  Permission = Permission
  CustomerPermissions = CustomerPermissions
  userPermissions$ = this.permissionStore.userPermissions$
  currentUser$: Observable<IEmployerUser | null> = this.store.select(selectors.getCurrentUser)

  customerName$ = this.customerStore.currentCustomer$.pipe(map(c => c?.name))
  customTokenUrl$ = this.authStore.customToken$.pipe(
    map(token => (token ? this.getDynamicLinkForToken(token.token, token.tenantId, token.tenantUrl) : null))
  )

  constructor(
    private store: Store,
    private permissionStore: PermissionStore,
    private customerStore: CustomerStore,
    private modalService: E11ModalGlobalService,
    private authStore: AuthStore
  ) {
    this.authStore.onGetCustomToken()
  }

  ngOnInit() {}

  signOut() {
    this.store.dispatch(new OnLogOut())
  }

  getDynamicLinkForToken(token: string, tenant: string, tenantUrl: string | undefined) {
    let appUrl = tenantUrl ?? environment.employerBaseUrl
    appUrl = appUrl.endsWith('/') ? appUrl : appUrl + '/'
    return `${environment.services.cloudFunctions}/dynamicLink/li/link?href=${appUrl}login-with-token/${token}&tenant=${tenant}&appKey=employer`
  }

  refreshCustomToken() {
    this.authStore.onGetCustomToken()
  }

  openQRCodeModal() {
    this.modalService.openModal({
      body: this.qrCodeModalContent,
      width: 'sm',
      title: 'Sign in on mobile',
    })
  }
}
